/* 레귤러 폰트 */
@font-face {
    font-family: 'Dongle';
    src: url('./fonts/Dongle-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  /* 볼드 폰트 */
  @font-face {
    font-family: 'Dongle';
    src: url('./fonts/Dongle-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  /* 라이트 폰트 */
  @font-face {
    font-family: 'Dongle';
    src: url('./fonts/Dongle-Light.ttf') format('truetype');
    font-weight: 300; /* 라이트 폰트에 대한 일반적인 가중치 값 */
    font-style: normal;
  }

  @font-face {
    font-family: 'Godo';
    src: url('./fonts/GodoM.ttf') format('truetype');
  
    font-style: normal;
  }
  @font-face {
    font-family: 'NanumGothic';
    src: url('./fonts/NanumGothic-Regular.ttf') format('truetype');
    font-weight: 400; /* Regular */
    font-style: normal;
}

@font-face {
    font-family: 'NanumGothic';
    src: url('./fonts/NanumGothic-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
}

@font-face {
    font-family: 'NanumGothic';
    src: url('./fonts/NanumGothic-ExtraBold.ttf') format('truetype');
    font-weight: 800; /* Extra Bold */
    font-style: normal;
}


  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    /* background: linear-gradient(to top right, #fad1bd, #fff1ea);  그라데이션 적용 */
   background: #f7f7f7;
   font-weight: bold; /* 텍스트를 볼드로 만들기 */
   margin: 0px;
    min-height: 100vh;
  }
  