/* ServiceIntroPage.css */

@font-face {
    font-family: 'NanumGothic';
    src: url('/src/fonts/NanumGothic-Regular.ttf') format('truetype');
    font-weight: 400; /* Regular */
    font-style: normal;
}

@font-face {
    font-family: 'NanumGothic';
    src: url('/src/fonts/NanumGothic-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
}

@font-face {
    font-family: 'NanumGothic';
    src: url('/src/fonts/NanumGothic-ExtraBold.ttf') format('truetype');
    font-weight: 800; /* Extra Bold */
    font-style: normal;
}
.service-intro-container {
    text-align: left;
    padding: 15px;
  }
  
  .titleintro {
    font-size: 1.7em;
    margin-bottom: 40px;
    margin-top: 24px;
  }
  
  .subtitleintro {
    font-size: 1.0em;
    margin-bottom: 30px;
    line-height: 1.5;
   /* font-family: 'NanumGothic'; */
    font-weight: 700;
  }
  
  .contentintro {
    font-size: 1em;
    line-height: 1.5;
  /*  font-family: 'NanumGothic'; */
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .image-container {
    background-image: url('../images/servi.webp');
    background-size: cover; /* 이미지가 컨테이너를 꽉 채우도록 설정 */
    background-position: center; /* 이미지가 중앙에 위치하도록 설정 */
    height: 270px; /* 원하는 높이 설정 */
    width: 100%; /* 컨테이너의 전체 너비를 채우도록 설정 */
    border-radius: 20px; /* 모서리를 둥글게 */

  }
  

  