.search-container {
    display: flex;
    width: calc(100% - 60px); /* 부모 컨테이너 너비에서 40px 뺀 값으로 설정 */
    margin: 14px auto; /* 좌우 자동 정렬로 가운데 정렬 */

    border: none;
    border-radius: 60px;
    padding: 7px 20px;
    background-color: #ffffff;
  }
  
  .search-input {
    flex-grow: 1;
    background-color: #ffffff;
    border: none;
    outline: none; /* 포커스 시 테두리 없애기 */
  }
  
  .search-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    border: none;
    background-image: url('/src/images/searchicon.webp');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .no-results {
    color: grey; /* 글자 색상을 회색으로 설정 */
    text-align: center; /* 텍스트를 가운데 정렬 */
    margin-top: 20px; /* 상단 여백 추가 */
  }
  
  .searchtitle {
    font-size: 1.3em;
    margin-left: 26px;
    margin-top: 28px;
    color: rgb(65, 65, 65);
  }
  .searchtitle2 {
    color: rgb(65, 65, 65);
    font-size: 1.3em;
    margin-left: 26px;
    margin-top: -10px;
    padding-top: 0px;
  }
  
  .orange-text2 {
    color: #ff6700; /* 주황색 적용 */
    display: inline-block; /* 회전을 위해 inline-block 또는 block 필요 */
    transform: rotate(-6deg); /* 10도 회전 */
    font-size: 1.1em; 
    
  }

  .sub-button{
    color: #000000; 
  margin-left: 20px;
    font-size: 1.1em; 
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 18px;
    padding-right: 18px;
    background-color: white;
    border-radius: 20px;
    box-shadow:   8px 8px 15px #eceae8, /* 부드러운 외부 그림자 */
    -8px -8px 15px #ffffff; /* 부드러운 내부 그림자 */
  transition: box-shadow 0.3s ease; /* 부드러운 그림자 변환 효과 */
    border: 0px
    
    
  }
  