@font-face {
    font-family: 'NanumGothic';
    src: url('/src/fonts/NanumGothic-Regular.ttf') format('truetype');
    font-weight: 400; /* Regular */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'NanumGothic';
    src: url('/src/fonts/NanumGothic-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'NanumGothic';
    src: url('/src/fonts/NanumGothic-ExtraBold.ttf') format('truetype');
    font-weight: 800; /* Extra Bold */
    font-style: normal;
  }
   /* 볼드 폰트 */
   @font-face {
    font-family: 'Dongle';
    src: url('/src/fonts/Dongle-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  .food {
    margin-top: 40px;
    margin-left: 20px;
  /*  font-family: 'NanumGothic'; */
  
    font-weight: 700;
    color: #000000;
  }
.center-text {
    margin-top: 10px;
    margin-left: 20px;
   /*   font-family: 'NanumGothic'; */
    text-align: start;
    font-weight: 700;
    color: #000000;
  }
  
  .price {
    text-align: center;
    font-size: 60px;
    padding: 10px;
   
  }
  .lucky-title {
    color: rgb(255, 255, 255); /* 글자색 */
    font-size: 0.9em;
    font-weight: bold; /* 텍스트를 볼드로 만들기 */
  }
  .lucky-contents {
    color: rgb(14, 14, 14); /* 글자색 */
   
    font-size: 0.9em;
    margin-bottom: 1px;
  }
  .lucky-contents2 {
    color: rgb(0, 0, 0); /* 글자색 */
   
    margin-top: 12px;
    font-size: 1em;
  }
  .title-store {
    margin-top: 40px;
   /*   font-family: 'NanumGothic'; */
    text-align: start;
    font-weight: 400;
    color: #5e47a8;
    background-color: #ffffff; /* 배경색을 원하는 색으로 설정 */
    border-radius: 15px; /* 둥근 모서리의 정도를 조절 */
    padding: 20px; /* 내부 여백을 조절 */
   /*  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 선택적으로 그림자 효과 추가 */
}

.container {
  display: flex; /* Enables flex layout */
  justify-content: space-around; /* Evenly space the child elements */

}

.price-area, .location-area {
  flex: 1; /* Each takes up equal space */
  background-color: rgb(255, 255, 255); /* Grey background */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside the areas */
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 10px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */

}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;

  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.slide-in {
 
  animation: slideInFromLeft 0.5s cubic-bezier(0, 0, 0.58, 1) forwards;
}

.price-areaver2 {
  /* Initially set to hidden to not show before animation */
  visibility: hidden;
  /* Other styles... */
}

/* When the slide-in class is added, change visibility to visible */
.price-areaver2.slide-in {
  visibility: visible;
}

.price-areaver2.slide-out {
  visibility: visible;
  animation: slideOutToLeft 1s cubic-bezier(0, 0, 0.58, 1) forwards;
}
.price-areaver2 {
  flex: 1; /* Each takes up equal space */
  background-color: #ff6700; /* Grey background */
  border: 0px solid #ffffff;
  background: rgb(252,176,69);
  background: linear-gradient(90deg, rgba(252,176,69,1) 0%, rgba(255,141,0,1) 50%, rgba(255,103,0,1) 100%);
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside the areas */
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 30px;
  margin: 10px; /* Margin around the areas */
  margin-top: 40px;
  box-shadow: 
  -7px 7px 10px 0px rgba(0,0,0,.1), /* 왼쪽 아래로 그림자 */
  -4px 4px 5px 0px rgba(0,0,0,.1); /* 왼쪽 아래로 그림자 */

  /* animation: 8.5s cubic-bezier(0, 0, 0.58, 1) 0s 1 slideInFromLeft; */

 

}


.price-area h5, .price-area p,
.location-area h5, .location-area p {
  color: black; /* Text color */
  text-align: start; /* Center the text */
  line-height: 1; /* 라인 높이 조정 */
}

.price-area h5, .location-area h5 {
  color: rgb(251, 128, 128); /* Text color */
}

.map {
 
  height: 400px;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: medium;
  border: 1px solid #ff6700;
  border-radius: 20px; 
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,5),
  -7px 7px 10px 0px rgba(0,0,0,.1), /* 왼쪽 아래로 그림자 */
  -4px 4px 5px 0px rgba(0,0,0,.1); /* 왼쪽 아래로 그림자 */

}


/* CSS */
.copy-button {
  background-color: #ff6700;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 4px;
  margin-top: 1px;
  cursor: pointer;
}
.tmap-button {
  background-color: #ffffff;
  color: #008cff;
  border: 1px solid #d900ff; /* 테두리 추가 */
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 4px;
  margin-top: 1px;
  cursor: pointer;
}
/* PC 브라우저에서는 T맵 버튼 숨기기 */
.tmap-button {
  display: none;
}

/* 모바일 브라우저에서만 T맵 버튼 표시 */
@media screen and (max-width: 1000px) {
  .tmap-button {
      display: inline;
  }
}

.toast-message {
  visibility: hidden; /* 기본적으로 보이지 않음 */
  min-width: 250px;
  background-color: rgba(51, 51, 51, 0.6); /* 검정색에 투명도 0.8 적용 */
  color: white; /* 글자색 */
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 90px;
}

.toast-message.show {
  visibility: visible; /* 보이게 함 */
}

/* 버튼 스타일 */

.button-container {
  display: flex;
  justify-content: flex-end; /* Centers the button horizontally */
}

.share-button {
  margin-bottom: 20px;
 /*   font-family: 'NanumGothic'; */
  text-align: center;
  font-weight: 700;
  background-color: #f9e000; /* 라운드 스퀘어 배경 색상 */
  color: #3b1b1c; /* 검은 글씨 */
  border: none; /* 테두리 없애기 */
  border-radius: 20px; /* 둥근 모서리 만들기 */
  padding: 10px 20px; /* 상하좌우 패딩 */
  font-size: 0.9em; /* 글씨 크기 */
  cursor: pointer; /* 마우스 오버 시 커서 변경 */
  transition: background-color 0.3s; /* 클릭 효과를 위한 배경색 변경 애니메이션 */
}
.share-button2 {
  margin-bottom: 20px;
  margin-right: 10px;
  /*  font-family: 'NanumGothic'; */
  text-align: center;
  font-weight: 700;

  color: #3b1b1c; /* 검은 글씨 */
  border: none; /* 테두리 없애기 */
 
  padding: 10px 20px; /* 상하좌우 패딩 */

 
}



/* 버튼 호버 시 */
.share-button:hover {
  background-color: #d8c000; /* 호버 시 색상 변경 */
}
.restaurant-temp {
  font-style: italic; /* 이탤릭체로 설정 */
}

.open-hour {
  color: rgb(100, 100, 100);
  font-size: 0.9em;
  line-height: 1; /* 라인 높이 조정 */
  
}
.addre {
 
  font-weight: normal;
}


/* CSS 파일 내에 */

.infowindow-style {
  padding: 5px;
  text-align: center;
  background-color: #ff6700;
  color: white;
  width: 150px;
  animation: sparkle 10s infinite;
  
}

@keyframes sparkle {
  0% {
    background-color: #ff6700;
    opacity: 1;
  }
  50% {
    background-color: #ff7700;
    opacity: 0.8;
  }
  100% {
    background-color: #ff6700;
    opacity: 1;
  }
}


