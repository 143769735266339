/* Footer.css */
.footer-container {
    background-color: #f9e3e3; /* 밝은 회색 배경 */
    color: #333; /* 어두운 글자색 */
    text-align: left; /* 텍스트 중앙 정렬 */
    padding: 20px 0; /* 상하 패딩 */
    bottom: 0;
    width: 100%;
    
  }
  
  .footer-content {
    margin: 0 auto;
    width: calc(100% - 34px);
    padding: 0 10px; /* 좌우 패딩 */
    font-size: 0.6em;
  }
  .footer-contentin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 14px;
    margin-top: 0px;
    /* your existing styles */
  }
    .title2 {
    color: black;
    font-weight: bold;
    text-align: start;
    font-size: 20px; 
    margin: 0;
    margin-bottom: 26px;
    flex-grow: 1;
  }
  .orange-text {
    color: #ff6700; /* 주황색 적용 */
    display: inline-block; /* 회전을 위해 inline-block 또는 block 필요 */
    transform: rotate(-6deg); /* 10도 회전 */
    font-size: 23px; 
    
  }

  .contentfoot {
    color: rgb(41, 41, 41);
    text-align: start;
    font-size: 13px; 
    margin: 0;
    margin-bottom: 10px;
    flex-grow: 1;
  }

  /* 버튼 애니메이션을 위한 keyframes 정의 */
  @keyframes jelly {
    0%, 100% {
      transform: scale(1); /* 원래 크기 */
    }
    50% {
      transform: scale(0.7); /* 약간 축소 */
    }
  }
  .submit-buttonf {
    background-color: #ba9175;
    color: white;
    border: none;
    padding: 10px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.6em; 
    display: block; /* 블록 레벨 요소로 만듭니다 */
    margin-left: auto; /* 왼쪽 마진을 자동으로 설정하여 오른쪽 정렬합니다 */
    margin-right: -2px; /* 필요한 경우 오른쪽 마진을 0으로 설정 */
    margin-bottom: 26px;
    /* 애니메이션 추가 */
  transition: transform 0.3s; /* 애니메이션 지속시간과 효과 */
  }
  /* 버튼 클릭 시 애니메이션 효과 적용 */
.submit-buttonf:active {
  animation: jelly 0.3s; /* 애니메이션 이름과 지속시간 지정 */
}