.submission-container {
    background-color: white;
    max-width: 100%;
    text-align: left;
  padding-left: 15px;
    padding-right: 35px;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  
  .main-title {
    color: black;
    margin-top: 10px;
    font-size: 1.7em;
  }
  
  .sub-title {
    color: rgb(145, 145, 145);
    font-size: smaller;
    margin-bottom: 50px;
  }
  
  .input-field {
    display: block;
   margin-top: 6px;
   margin-bottom: 6px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 10px;
    border-radius: 30px;
    border: 1px solid grey;
    width: calc(100% - 34px);
    font-size: 1.3em;
    padding-left: 40px; /* 아이콘이 텍스트와 겹치지 않도록 왼쪽 패딩을 조정 */
  background-image: url('/src/images/store2.webp'); /* 아이콘 이미지 URL */
  background-repeat: no-repeat;
  background-position: 10px center; /* 아이콘 위치 조정 */
  background-size: 20px; /* 아이콘 크기 조정 */
  }
  .input-field2 {
    display: block;
   margin-top: 6px;
   margin-bottom: 6px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 10px;
    border-radius: 30px;
    border: 1px solid grey;
    width: calc(100% - 34px);
    font-size: 1.3em;
    padding-left: 40px; /* 아이콘이 텍스트와 겹치지 않도록 왼쪽 패딩을 조정 */
  background-image: url('/src/images/storemenu2.webp'); /* 아이콘 이미지 URL */
  background-repeat: no-repeat;
  background-position: 10px center; /* 아이콘 위치 조정 */
  background-size: 20px; /* 아이콘 크기 조정 */
  }
  .input-field3 {
    display: block;
   margin-top: 6px;
   margin-bottom: 6px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 10px;
    border-radius: 30px;
    border: 1px solid grey;
    width: calc(100% - 34px);
    font-size: 1.3em;
    padding-left: 40px; /* 아이콘이 텍스트와 겹치지 않도록 왼쪽 패딩을 조정 */
  background-image: url('/src/images/storelocation.webp'); /* 아이콘 이미지 URL */
  background-repeat: no-repeat;
  background-position: 10px center; /* 아이콘 위치 조정 */
  background-size: 20px; /* 아이콘 크기 조정 */
  }


  /* 버튼 애니메이션을 위한 keyframes 정의 */
@keyframes jelly {
  0%, 100% {
    transform: scale(1); /* 원래 크기 */
  }
  50% {
    transform: scale(0.5); /* 약간 축소 */
  }
}
  .submit-button {
    background-color: #ff6700;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 1.1em; 
    cursor: pointer;
    margin-top: 50px;
    display: block; /* 블록 레벨 요소로 만듭니다 */
    margin-left: auto; /* 왼쪽 마진을 자동으로 설정하여 오른쪽 정렬합니다 */
    margin-right: -20px; /* 필요한 경우 오른쪽 마진을 0으로 설정 */
    margin-bottom: 60px;
    /* 애니메이션 추가 */
  transition: transform 0.3s; /* 애니메이션 지속시간과 효과 */
  }
  /* 버튼 클릭 시 애니메이션 효과 적용 */
.submit-button:active {
  animation: jelly 0.3s; /* 애니메이션 이름과 지속시간 지정 */
}
  
  .subtitle {
    color: rgb(251, 128, 128); /* Text color */
margin-bottom: 0px;  
margin-left: 12px;
margin-top: 30px;
}
  