/* RestaurantCard.css */
@font-face {
  font-family: 'NanumGothic';
  src: url('/src/fonts/NanumGothic-Regular.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'NanumGothic';
  src: url('/src/fonts/NanumGothic-Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'NanumGothic';
  src: url('/src/fonts/NanumGothic-ExtraBold.ttf') format('truetype');
  font-weight: 800; /* Extra Bold */
  font-style: normal;
}

.restaurant-card {
  min-height: 130px; /* 카드의 최소 높이를 150픽셀로 설정 */
    background-color: #fff; /* 카드 배경 색상 */
    border-radius: 20px; /* 둥근 모서리 */
    padding: 6px; /* 내부 여백 */
    padding-left: 15px;
    padding-right: 15px;
    margin: 19px 0; /* 카드 간격 */
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-start; /* Adjusts alignment to start from left */
    align-items: center;
    box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,5),
    -7px 7px 10px 0px rgba(0,0,0,.1),  /*왼쪽 아래로 그림자 */
    -4px 4px 5px 0px rgba(0,0,0,.1);  /*왼쪽 아래로 그림자 */

    border: 0px solid #ff6700;
    position: relative;
    overflow: hidden; /* Ensure the shine effect stays within the borders */
  }
  .restaurant-card:before {
    content: '';
    position: absolute;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    
  }
  .restaurant-card:hover:before {
    animation: shiny-effect 0.5s linear forwards;
  }
  @keyframes shiny-effect {
    0% { transform: scale(1) rotate(45deg); opacity: 0.2; }
    80% { transform: scale(2) rotate(45deg); opacity: 0.5; }
    81% { transform: scale(4) rotate(45deg); opacity: 1; }
    100% { transform: scale(50) rotate(45deg); opacity: 0; }
  }

  
  .restaurant-food {
    font-size: 0.9em; /* 제목 글씨 크기 */
    color: #8e8e8e; /* 글씨 색상 */
    margin-bottom: 8px; /* 제목과 가격 사이의 간격 */
  }
  
  .restaurant-price {
    font-size: 1.3em; /* 가격 글씨 크기 */
    color: #000000; /* 가격 글씨 색상 */
    margin-top: 0px;
  }

  .restaurant-info {
    flex-grow: 1; /* Allows this section to grow */
    max-width: 150px; 
    margin-left: 20px;
    padding-top: 5px;
    display: flex;
    flex-direction: column; /* 항목을 세로로 쌓음 */
  }

  .restaurant-location {
   /* font-family: 'NanumGothic';*/
    font-weight: 700;
    flex-grow: 1; /* Allows this section to grow */
    max-width: 110px; /* Set a maximum width */
    margin-left: auto; /* Pushes it to the right */
    padding-right: 10px;
    font-size: 0.9em; /* 가격 글씨 크기 */
    color: #000000; /* 가격 글씨 색상 */
    text-align: right; /* 텍스트를 오른쪽으로 정렬 */

  }

  .restaurant-distance {
  /*  font-family: 'NanumGothic';*/
    font-weight: 700;
    flex-grow: 1; /* Allows this section to grow */
    min-width: 110px; /* Set a maximum width */
    margin-left: auto; /* Pushes it to the right */
    padding-right: 10px;
    font-size: 0.8em; /* 가격 글씨 크기 */
    color: #ff6700; /* 가격 글씨 색상 */
    text-align: right; /* 텍스트를 오른쪽으로 정렬 */

  }

  @keyframes puff {
    0% { transform: scale(1); }
    50% { transform: scale(1.25); } /* 숫자가 잠시 커짐 */
    100% { transform: scale(1); }
}

.puff-effect {
    animation: puff 0.5s ease-out forwards; /* 애니메이션 지속시간과 타이밍 함수 설정 */
}

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

  