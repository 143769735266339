@keyframes sparkling {
    0%, 100% { filter: brightness(1); }
    50% { filter: brightness(1.5); }
  }
  @keyframes clickAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(0.75); }
    100% { transform: scale(1); }
  }
  

.header {
    display: flex;
    flex-direction: column; /* 자식 요소들을 세로로 정렬 */
    align-items: center; /* 가운데 정렬 */
    justify-content: space-between;
    background-color: transparent;
    padding: 14px;
  }
  
  .header-content {
    display: flex;
     width: 100%; /* 너비를 최대로 설정하여 구분선과 같은 너비를 가지게 함 */
    align-items: center;
    justify-content: space-between;
  
  }
  
  
  .title {
    color: black;
    font-weight: bold;
    text-align: right;
    font-size: 1.4em; 
    margin-left: auto;
    margin-top: 0px;
    flex-grow: 1;
    font-weight: normal;
  }



  .orange-text {
    color: #ff6700; /* 주황색 적용 */
    display: inline-block; /* 회전을 위해 inline-block 또는 block 필요 */
    transform: rotate(-6deg); /* 10도 회전 */
    font-size: 23px; 
    
  }
  
  
  
   .add-button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 14px;
  }
  
  .menu-button {
    display: flex;
    flex-direction: column; /* 자식 요소를 수직으로 정렬 */
    align-items: center; /* 가운데 정렬 */
    justify-content: center; /* 수직 중앙 정렬 */
  }

  .menu-button-clicked {
    animation: clickAnimation 0.4s ease;
  }
  
  .menu-button .bar {
    width: 24px;
    height: 2px;
    background-color: black;
    margin: 1.4px 0; /* 상하 마진 추가 */
    border-radius: 5px;
  }
  
  
  
  /* .add-button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: rgb(255, 152, 255);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  } */

  .add-button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color:#ff6700;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;

   
    cursor: pointer;
    position: relative;
    display: inline-block;
    /* box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,5),
    -7px 7px 10px 0px rgba(0,0,0,.2), /* 왼쪽 아래로 그림자 */
    /* -4px 4px 5px 0px rgba(0,0,0,.2); 왼쪽 아래로 그림자 */

    outline: none;
  }

  
  
  .add-button strong {
    margin: 0; /* 모든 마진 제거 */
    margin-top: 1px; 
    padding: 0;
    display: flex; /* Flexbox 사용 */
    align-items: center; /* 세로 중앙 정렬 */
    justify-content: center; /* 가로 중앙 정렬 */
    height: 100%; /* 부모 요소와 높이 맞춤 */
    color: rgb(255, 255, 255);
    font-size: 1.4em; /* 글씨 크기를 더 키움 (예: 1.5em) */
    line-height: 1; /* line-height를 1로 설정하거나 제거 */
  }
  .add-button:hover strong {
    margin-top: 1px; 
    color: rgb(216, 215, 215);
  }



  
  

  .divider {
    height: 1.4px;
    background-color: transparent; /* 회색 */
    margin-top: 2px;
    width: 100%; /* 전체 너비 */
  }
  
  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    30% {
      transform: translateY(-5%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .nav-menu {
    background-color: transparent; /* 메뉴 배경색 */
    padding: 0px; /* 메뉴 내부 여백 */
    width: 100%; /* 메뉴 너비 */
    animation: slideDown 0.4s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(-100%);
  }
 
  
  .nav-menu.visible {
    transform: auto;
  }
  
  .nav-menu ul {
    list-style: none; /* 리스트 스타일 제거 */
    padding: 0; /* 리스트 여백 제거 */
  }
  
  .nav-menu li {
    padding: 10px 0; /* 각 항목의 상하 여백 */
  }
  
  .nav-menu a {
    color: black; /* 링크 색상 */
    text-decoration: none; /* 밑줄 제거 */
    font-size: 1em;
  }
  .nax1 {
    color: black; /* 링크 색상 */
    display: flex;
    font-size: 1.2em; 
    flex-direction: column; /* 자식 요소를 수직으로 정렬 */
    align-items: center; /* 가운데 정렬 */
    justify-content: center; /* 수직 중앙 정렬 */
  }
  .nax2 {
    color: black; /* 링크 색상 */
    display: flex;
    font-size: 1.2em; 
    flex-direction: column; /* 자식 요소를 수직으로 정렬 */
    align-items: center; /* 가운데 정렬 */
    justify-content: center; /* 수직 중앙 정렬 */
  }
  .nax3 {
    color: black; /* 링크 색상 */
    display: flex;
    font-size: 1.2em; 
    flex-direction: column; /* 자식 요소를 수직으로 정렬 */
    align-items: center; /* 가운데 정렬 */
    justify-content: center; /* 수직 중앙 정렬 */
  }
  .nax4 {
    color: black; /* 링크 색상 */
    display: flex;
    font-size: 1.2em; 
    flex-direction: column; /* 자식 요소를 수직으로 정렬 */
    align-items: center; /* 가운데 정렬 */
    justify-content: center; /* 수직 중앙 정렬 */
  }



  
  
  
  
  