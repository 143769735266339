@font-face {
    font-family: 'NanumGothic';
    src: url('/src/fonts/NanumGothic-Regular.ttf') format('truetype');
    font-weight: 400; /* Regular */
    font-style: normal;
}

.price-area2 {
    flex: 1;
    background-color: #f7f6f5; /* 부드러운 배경색 */
    border-radius: 10px;
    padding: 20px;
    margin: 30px;
    box-shadow: 
      8px 8px 15px #a3b1c6, /* 부드러운 외부 그림자 */
      -8px -8px 15px #ffffff; /* 부드러운 내부 그림자 */
    transition: box-shadow 0.3s ease; /* 부드러운 그림자 변환 효과 */
    cursor: pointer;
}

.price-area2:hover {
    box-shadow: 
      4px 4px 10px #a3b1c6, /* 호버 상태에서의 외부 그림자 조정 */
      -4px -4px 10px #ffffff; /* 호버 상태에서의 내부 그림자 조정 */
}


  .titlereview2 {
    text-decoration: none; /* 밑줄 제거 */
    color: #000000; 
    font-size: 1.1em;
  }
  .disc2{
    line-height: 1.4; /* 텍스트 크기의 2배로 변경 */
    font-size: 1em;
    margin-bottom: 10px; /* 필요에 따라 조정 */
 
    font-weight: 400;

  }
  .tta{
    color: #ff6700;
    margin-left: 30px;
    font-size: 1.1em;
    margin-top: 50px;

  }

 